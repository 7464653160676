body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Bold.aa65e441.woff2) format("woff2"),
    url(/static/media/HelveticaNeue-Bold.a2c52896.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Medium.9679e27a.woff2) format("woff2"),
    url(/static/media/HelveticaNeue-Medium.28a0f7a7.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Medium.9679e27a.woff2) format("woff2"),
    url(/static/media/HelveticaNeue-Medium.28a0f7a7.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue.6c15b021.woff2) format("woff2"), url(/static/media/HelveticaNeue.281af44e.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Light.db8145ba.woff2) format("woff2"),
    url(/static/media/HelveticaNeue-Light.a42434a0.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-Thin.8da35331.woff2) format("woff2"),
    url(/static/media/HelveticaNeue-Thin.53510192.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(/static/media/HelveticaNeue-UltraLight.1c3f8831.woff2) format("woff2"),
    url(/static/media/HelveticaNeue-UltraLight.832e8044.woff) format("woff");
  font-weight: 100;
  font-style: normal;
}

/*
@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-CondensedBlack.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-CondensedBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-MediumItalic.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-BoldItalic.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-LightItalic.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-Italic.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-CondensedBold.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-CondensedBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-ThinItalic.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./../fonts/HelveticaNeue-UltraLightItalic.woff2") format("woff2"),
    url("./../fonts/HelveticaNeue-UltraLightItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
*/

html,
body {
  max-width: 100%;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
span {
  padding: 0;
  margin: 0;
}

div,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
span:focus {
  outline: 0;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

#nprogress {
  position: absolute;
  z-index: 99999 !important;
}

#nprogress .bar {
  height: 4px !important;
  background: #048ce0 !important;
}

#nprogress .peg {
  box-shadow: 0 0 0 transparent, 0 0 0 transparent !important;
}

#nprogress .spinner {
  top: 30px !important;
}

#nprogress .spinner-icon {
  display: none;
}

div {
  -ms-overflow-style: none;
}

/*
div {
  overflow: -moz-scrollbars-none;
}
*/

::-webkit-scrollbar {
  display: none;
}

#root {
  overflow: hidden;
}

