html,
body {
  max-width: 100%;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
span {
  padding: 0;
  margin: 0;
}

div,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
span:focus {
  outline: 0;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

#nprogress {
  position: absolute;
  z-index: 99999 !important;
}

#nprogress .bar {
  height: 4px !important;
  background: #048ce0 !important;
}

#nprogress .peg {
  box-shadow: 0 0 0 transparent, 0 0 0 transparent !important;
}

#nprogress .spinner {
  top: 30px !important;
}

#nprogress .spinner-icon {
  display: none;
}

div {
  -ms-overflow-style: none;
}

/*
div {
  overflow: -moz-scrollbars-none;
}
*/

::-webkit-scrollbar {
  display: none;
}

#root {
  overflow: hidden;
}
